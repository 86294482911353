<template>
  <div id="dashboard">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="book" /> Control Documental</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-tabs class="card" card fill>
                <b-tab
                  v-for="(item, index) in desserts"
                  :key="index"
                  :title="item.name"
                  ><p>{{ item.description }}</p>
                  <b-table
                    striped
                    responsive
                    stacked="lg"
                    :items="item.documentaries"
                    :fields="fields"
                  >
                  </b-table
                ></b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    desserts: [],
    fields: [
      { key: "username", label: "Nombre usuario" },
      { key: "typeRequisition", label: "Descripción" },
      { key: "observation", label: "Observación" },
    ],
  }),
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      let res = await this.$axios
        .get("/documentary", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
      var aux = [];
      for (var i in res.data) {
        var obj = {
          name: res.data[i].name,
          description: res.data[i].description,
          documentaries: res.data[i].documentaries,
        };
        aux.push(obj);
      }
      this.desserts = aux;
    },
  },
};
</script>

<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.nav-tabs .nav-link.active {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  color: black;
  font-weight: bolder;
}
</style>